.dx-datagrid .dx-header-row > td.dx-datagrid-action {
  padding: 0;
}
.dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-left,
.dx-header-row .dx-sort-indicator.dx-text-content-alignment-left {
  margin-right: 0;
}
.dx-header-row .dx-header-filter-indicator.dx-text-content-alignment-right,
.dx-header-row .dx-sort-indicator.dx-text-content-alignment-right {
  margin-left: 0;
}
.dx-datagrid .dx-header-row > td > .dx-column-indicators {
  display: none;
}
.dx-datagrid-nowrap .dx-header-row > td > .dx-datagrid-text-content {
  width: 100%;
  padding: 10px;
}
.dx-datagrid-nowrap
  .dx-header-row
  > td
  > .dx-datagrid-text-content.dx-sort-indicator {
  background: #d3d3d3;
  height: 100%;
}
.column-heading-caption {
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-sort-icons {
  color: #d2d4d6;
  text-align: right;
}
.header-sort-icons > i:first-child {
  height: 8px;
}
.dx-header-row .dx-sort-indicator {
  max-width: 100%;
}
.header-sort-icons .sorted {
  color: #495057;
  padding-top: 3px !important;
  font-size: 16px;
}
.trial-expiry-msg {
  font-size: 14px;
  margin: 10px 0px 0px 3px;
  font-weight: bold;
  color: red;
}
.sc-page-header {
  margin-bottom: 20px;
}
.sc-page-header .page-heading {
  margin-bottom: 0;
}
