/* .sc-button:focus {
  outline: #1a285670 solid 3px !important;
} */

.icon-part.btn {
  width: auto;
  text-align: center;
}

.text-part.btn {
  width: auto;
  text-align: center;
}

.sc-button>.icon-part.btn,
.sc-button>.icon-part.btn-primary:active,
.sc-button>.icon-part.btn-primary:focus {
  color: #242a75 !important;
  border: 1px solid #242a75;
  padding: 4.05px 12px;
  overflow: hidden;
  background: linear-gradient(to left, #1a2856 50%, white 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 0.3s ease-out;
}

.sc-button>.icon-part.order-last.btn,
.sc-button>.icon-part.order-last.btn-primary:active,
.sc-button>.icon-part.order-last.btn-primary:focus {
  background: linear-gradient(to right, #1a2856 50%, #ffffff 50%);
  background-size: 200% 100%;
  background-position: right top;
}

.sc-button>.text-part.btn,
.sc-button>.text-part.btn-primary:active,
.sc-button>.text-part.btn-primary:focus {
  font-size: 14px;
  padding: 7px 12px;
  background-color: #242a75 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.sc-button:hover>.text-part.btn.btn-primary,
.sc-button:hover>.text-part.btn.btn-primary:hover {
  background: #1a2856 !important;
}

.sc-button:hover>.icon-part.btn.btn-primary {
  background-position: right top;
  color: #ffffff !important;
}

.sc-button:hover>.icon-part.order-last.btn.btn-primary {
  background-position: left bottom;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.large-spin-icon {
  font-size: 19px !important;
  margin-right: 10px;
}

.disabled-loader-btn {
  pointer-events: none;
}

.metadataButton {
  height: 40px;
}