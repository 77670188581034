.ChangePassword .row {
  margin: 0px 0px 25px;
}

.ChangePassword .buttons-row,
.ChangePassword .sc-button.row {
  margin: 0px;
}

.ChangePassword .buttons-row {
  margin-top: 5px;
}
.ResetPassword .back-to-lgn {
  margin-top: 30px;
}

.ResetPassword .timer-icon {
  padding: 3px;
}

.ChangePassword .login-msg {
  text-align: center;
  padding: 0 3px;
}
