hr {
  margin: 0.3rem 0.1rem 0rem 0.1rem; }

.login-msg {
  color: red;
  margin-top: 30px; }

.sidebar-toggle {
  padding: 10px 0px;
  text-align: right; }

.sidebar-toggle .navbar-toggle .navbar-toggler > i {
  color: #333333;
  font-size: 10px; }

.sidebar-toggle .navbar-toggle .navbar-toggler:hover > i {
  color: #00bbdd; }

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none; }

.page-heading {
  font-size: 24px;
  text-align: left;
  margin: 30px 0px; }

.filters-row {
  margin-bottom: 30px; }

.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: none;
  border-right: none; }

.simple-sc-btn.sc-button > .text-part.btn.btn-primary,
.simple-sc-btn.sc-button:hover > .text-part.btn.btn-primary,
.simple-sc-btn.sc-button:hover > .text-part.btn.btn-primary:hover {
  background-color: #ffffff !important;
  color: #242a75; }

.simple-sc-btn.sc-button:hover > .text-part.btn.btn-primary {
  text-decoration: underline; }

.dx-texteditor input[type="text"],
.dx-texteditor input[type="password"] {
  border: none !important; }

.dx-texteditor-input {
  padding: 0px 9px 4px; }

.dx-texteditor.dx-state-readonly {
  background-color: #efefef; }

.menu-tooltip .tooltip-inner {
  background-color: #ffffff;
  color: #242a75; }

.menu-tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #ffffff; }

.user-profile-dropdown.collapse:not(.show) {
  display: block; }

.change-password-popup {
  max-width: 540px;
  height: auto;
  margin: auto; }

.sc-popup .modal-body {
  padding: 30px 0px 40px; }

.sc-popup .modal-header {
  background-color: #d2d4d6;
  padding: 8px 15px;
  height: 40px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.sc-popup .modal-header .close {
  padding: 8px 15px;
  outline: none;
  opacity: 1;
  color: #333333; }

.sc-popup .modal-header .close:hover {
  opacity: 1; }

.sc-popup .modal-header .modal-title {
  font-size: 16px;
  color: #333333; }

.btn:focus {
  box-shadow: none; }

.empty-loading-container {
  width: 100%;
  height: 87vh; }

.dx-popup-content {
  padding: 10px; }

.form-control {
  border: 1px solid #1a2856 !important;
  font-size: 13px !important;
  max-width: 330px;
  height: 40px; }

.login-submit.btn.btn-block,
.login-submit.btn-block,
.login-submit.btn-group-block > .btn {
  max-width: 330px;
  height: 44px;
  padding: 0px;
  margin-top: 5px; }

.login-submit.btn-primary.active,
.login-submit.btn-primary:hover,
.login-submit.btn-primary:active,
.login-submit.btn-primary:focus {
  background-color: #1a2856 !important; }

.back-to-lgn {
  margin-top: 25px;
  margin-bottom: 0px; }

.forgot-submit.btn.btn-block {
  margin-top: 30px; }

.dx-tab:not(.child-tab-panel *) {
  box-shadow: none !important;
  padding: 9px 0px;
  border-bottom: 5px solid #797979;
  color: #1a2856;
  position: inherit;
  min-width: 80px;
  max-width: 180px; }

.dx-tabpanel .dx-tabs:not(.child-tab-panel *) {
  background-color: #ffffff;
  margin-right: 20px;
  border-bottom: 1px solid #fffdfd;
  box-shadow: none !important;
  /* border-top: 1px solid #dddddd; */ }

.dx-tabs:not(.child-tab-panel *) {
  border: 0px; }

.dx-tab.dx-tab-selected:not(.child-tab-panel *) {
  font-weight: bold;
  border-bottom: 5px solid #00bbdd;
  color: #1a2856; }

.dx-tabpanel .dx-multiview-wrapper:not(.child-tab-panel *) {
  border: 0px; }

.dx-tabs-wrapper:not(.child-tab-panel *) {
  display: table;
  border-collapse: separate;
  border-spacing: 35px 0px;
  height: 48px; }

.success-msg {
  color: green;
  padding-left: 5px; }

.metadata-sucess-msg {
  color: green;
  padding-left: 5px;
  min-width: max-content; }

.failure-msg {
  color: red;
  padding-left: 5px; }

.details-group {
  border: 1px solid #cecaca;
  padding: 0px 15px !important;
  padding-top: 0px !important;
  margin: 10px;
  height: 100%; }

.details-group-withoutBorder {
  padding: 0px 15px !important;
  padding-top: 0px !important;
  margin: -3px;
  margin-top: 10px;
  height: 100%; }

.group-heading {
  background-color: #e9e9e9;
  padding-left: 15px !important;
  height: 40px; }

.details-group .group-heading {
  margin: 0 -15px; }

.group-heading h5 {
  margin: 8px 0px; }

.rules-group {
  border: 1px solid #dcdcdc;
  margin: 10px 0px; }

.rules-group .head-group {
  padding: 0 15px 0 13px !important; }

.dx-layout-manager .rules-group.dx-field-item:not(.dx-first-row) {
  padding-top: 0px; }

.dx-layout-manager .dx-field-item:not(.dx-last-col) {
  padding-right: 10px; }

.dx-layout-manager .dx-field-item:not(.dx-first-col) {
  padding-left: 10px; }

.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-pager {
  border-top: 0px; }

.dx-layout-manager .client-form-inputs .dx-field-item:not(.dx-first-row) {
  padding-top: 25px; }

.dx-layout-manager.dx-layout-manager-one-col .dx-box-item:not(:first-child) .dx-single-column-item-content > .rules-group.dx-field-item {
  padding-top: 0px; }

.client-form-inputs .dx-texteditor.dx-editor-outlined:not(.child-texteditor *) {
  max-width: 400px;
  height: 35px; }

.client-form-inputs {
  margin: 20px 0px 30px; }

.button-item {
  padding-right: 5px !important;
  padding-left: 0px !important; }

.form-save-btn .dx-button-mode-contained {
  color: #ffffff;
  background-color: #242a75;
  border-radius: 0;
  padding: 0px;
  width: 130px; }

.form-save-btn:hover .dx-button-mode-contained {
  background-color: #1a2856; }

.form-cancel-btn .dx-button-mode-contained {
  color: #242a75;
  background: none;
  border: none;
  padding: 0px;
  width: 130px; }

.form-cancel-btn:hover .dx-button-mode-contained {
  text-decoration: none; }

.dx-tabpanel.dx-state-focused .dx-multiview-wrapper {
  border: none; }

.dx-tabpanel.dx-state-focused .dx-tab-selected:after {
  border: none; }

.dx-tabs-scrollable .dx-tabs-wrapper {
  border: none;
  border-bottom: 1px solid #ddd; }

.sidebar .nav li > a.quick-start-actions,
.sidebar .nav li > a.quick-start-actions i {
  color: #aaaaaa; }

.sidebar .nav li:hover,
.sidebar .nav li:hover:not(.active) > a.quick-start-actions i {
  color: #e4e4e8; }

.hide-icons a,
.hide-icons svg {
  display: none; }

.react-json {
  padding: 5px;
  border: 1px solid #f0f0f0;
  max-width: 710px !important;
  height: 500px !important;
  overflow: scroll; }

.content .sc-page-header a {
  color: #00bbdd; }

.custom-svg-icons {
  width: 15px;
  border-radius: 0.1875rem;
  margin: 0px 13px;
  padding-bottom: 4px; }

.documentation-tab-icon {
  width: 20px;
  margin: 0px 6px 0px 13px; }

.sidebar .nav .custom-svg-icons + p {
  display: inline;
  padding-left: 4px; }

.nav-toggle .menubar .sidebar-wrapper .nav li a .custom-svg-icons + p {
  display: none; }

.nav-toggle .menubar .sidebar-wrapper .nav li:hover a .custom-svg-icons + p {
  display: inline;
  padding-left: 18px;
  padding-right: 10px; }

@media screen and (max-width: 575px) {
  .change-password-popup {
    max-width: 364px; } }

@media screen and (max-width: 400px) {
  .dx-layout-manager .dx-label-h-align.dx-flex-layout {
    display: block; } }

.sc-cancel {
  padding: 6px 18px;
  width: 130px;
  text-align: center;
  font-size: 14px; }

.sc-cancel.btn.btn-link,
.sc-cancel.btn.btn-link:hover,
.sc-cancel.btn.btn-link:focus {
  color: #242a75;
  text-decoration: none; }

.sc-cancel.btn.btn-link:hover {
  text-decoration: underline; }

.dx-datagrid-content .custom-grid-icon,
.dx-datagrid-content .dx-command-edit-with-icons {
  text-align: left !important; }

.custom-grid-icon > svg,
.grid-icons {
  width: 16px;
  color: #333333;
  margin-right: 10px;
  margin-left: 5px; }

.custom-grid-icon > svg:hover {
  color: #00b2d7; }

.dx-datagrid-content tr:not(:first-child) .custom-grid-icon > svg,
.dx-datagrid-content tr:not(:first-child) .dx-command-edit-with-icons .grid-icons {
  visibility: hidden; }

.dx-datagrid-content tr:not(:first-child):hover .custom-grid-icon > svg,
.dx-datagrid-content tr:not(:first-child):hover .dx-command-edit-with-icons .grid-icons {
  visibility: visible; }
